let CryptoJS = require("crypto-js");
import moment from "moment";

const Utils = {
  getStore(key) {
    let _value = sessionStorage.getItem(key);
    _value = _value === null ? "" : _value;
    return _value;
  },
  setStore(key, value) {
    if (key && value !== "") {
      if (typeof value === "object") {
        value = JSON.stringify(value);
      }
      sessionStorage.setItem(key, value);
    }
  },
  removeStore(key) {
    if (this.getStore(key)) {
      sessionStorage.removeItem(key);
    }
  },
  getLocalstorageStore(key) {
    let _value = window.localStorage.getItem(key);
    _value = _value === null ? "" : _value;
    return _value;
  },
  setLocalstorageStore(key, value) {
    if (key && value !== "") {
      if (typeof value === "object") {
        value = JSON.stringify(value);
      }
      window.localStorage.setItem(key, value);
    }
  },
  removeLocalstorageStore(key) {
    if (this.getStore(key)) {
      window.localStorage.removeItem(key);
    }
  },
  clearLocalstorageStore() {
    window.localStorage.clear();
  },
  getCookie(name) {
    if (!document.cookie.length) return;
    let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    let arr = document.cookie.match(reg);

    if (Array.isArray(arr)) {
      return unescape(arr[2]);
    } else {
      return null;
    }
  },
  setCookie(name, value, days = 30) {
    let exp = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + escape(value) + ";expires=" + exp.toGMTString();
  },
  delCookie(name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = Utils.getCookie(name);
    if (cval != null) {
      document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
    }
  },
  padStart(sourceString) {
    if (typeof sourceString === "undefined") return "";
    return sourceString - 10 < 0 ? "0" + sourceString : sourceString;
  },
  encryptByDES(message) {
    if (!message) return;
    let key = "29582849";
    let iv = "98626432";
    key = CryptoJS.MD5(key);
    iv = CryptoJS.enc.Utf8.parse(iv);
    let encrypted = CryptoJS.DES.encrypt(message, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  },
  encrypt(word) {
    let key = CryptoJS.enc.Utf8.parse("29582849baaamall");
    let src = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(src, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  },
  decrypt(word) {
    let key = CryptoJS.enc.Utf8.parse("29582849baaamall");
    let decrypt = CryptoJS.AES.decrypt(word, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  },
  generateDateByString(str) {
    let result = {};
    if (str && typeof str === "string") {
      str = str.replace(/-/g, "/"); // 苹果safari, - 穿件日期NAN.
      result = new Date(str);
    } else {
      result = new Date();
    }
    return result;
  },
  getQueryString(name) {
    let str = `(^|&)${name}=([^&]*)(&|$)`;
    let reg = new RegExp(str, "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]);
    return null;
  },
  setBodyScroll(flag) {
    let body = document.getElementsByTagName("body")[0];
    if (flag) {
      body.style.position = "fixed";
    } else {
      body.style.position = "initial";
    }
  },
  // 获取屏幕宽度
  getWindowWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  },
  // 获取屏幕高度
  getWindowHeight() {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    );
  },
  // 处理async/await的异常
  async errorCaptured(asyncFunc) {
    try {
      let res = await asyncFunc();
      return [null, res];
    } catch (e) {
      return [e, null];
    }
  },
  debounce(func, wait = 50, immediate = false) {
    let timer, context, args;
    const later = () =>
      setTimeout(() => {
        timer = null;
        if (!immediate) {
          func.apply(context, args);
          context = args = null;
        }
      }, wait);
    return function (...params) {
      // 如果没有创建延迟执行函数（later），就创建一个
      if (!timer) {
        timer = later();
        // 如果是立即执行，调用函数
        // 否则缓存参数和调用上下文
        if (immediate) {
          func.apply(this, params);
        } else {
          context = this;
          args = params;
        }
        // 如果已有延迟执行函数（later），调用的时候清除原来的并重新设定一个
        // 这样做延迟函数会重新计时
      } else {
        clearTimeout(timer);
        timer = later();
      }
    };
  },
  // 去掉字符串左右空格
  trim(s) {
    return s.replace(/(^\s*)|(\s*$)/g, "");
  },
  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  },
  // 地区时间转换
  zoneTimeConvert(date, format, show = true) {
    const baseMin = 8 * 60;
    const now = new Date();
    const offsetTime = now.getTimezoneOffset();
    const diffMin = offsetTime + baseMin;

    // 转换当前地区时间
    if (show) {
      return moment(date).subtract(diffMin, "m").format(format);
    } else {
      // 转换北京时间
      return moment(date).add(diffMin, "m").format(format);
    }
  },
  // 埋点
  buryingPoint(params) {
    // 记录时间
    params.recordingTime = this.zoneTimeConvert(
      new Date(),
      "YYYY-MM-DD HH:mm:ss",
      false
    );
    this.$axios({
      url: "/appapi/sso/dataBuryingPoint/InviteToShareDateAdd",
      method: "post",
      data: params,
    }).then((res) => {
      console.log(res);
    });
  },

  // 拼音字符过滤
  pinyinFilter(data) {
    let chart = [
      ",",
      "，",
      ".",
      "。",
      "、",
      ";",
      "；",
      "“",
      "”",
      "_",
      "?",
      "？",
    ];
    return chart.includes(data);
  },

  getMobileOrPC() {
    let browser = {
      versions: (function () {
        var u = navigator.userAgent,
          app = navigator.appVersion;
        return {
          //移动终端浏览器版本信息
          trident: u.indexOf("Trident") > -1, //IE内核
          presto: u.indexOf("Presto") > -1, //opera内核
          webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
          gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
          mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
          android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
          iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
          iPad: u.indexOf("iPad") > -1, //是否iPad
          webApp: u.indexOf("Safari") == -1, //是否web应用程序，没有头部与底部
        };
      })(),
      language: (navigator.browserLanguage || navigator.language).toLowerCase(),
    };
    //如果是移动端就进这里
    if (
      browser.versions.mobile ||
      browser.versions.ios ||
      browser.versions.android ||
      browser.versions.iPhone ||
      browser.versions.iPad
    ) {
      return "web";
    } else {
      return "pc";
    }
  },
};

export default Utils;
