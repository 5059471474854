import axios from "axios";
import parser from "ua-parser-js";
import Utils from "@/script/utils";

// const host = "https://oms.admin.zhixinlive.com/api/"; // 测试地址
// const host = "https://oms.chineserd.com/api/"; // 正式地址
let host = "";
const currentDomain = window.location.hostname;
if (currentDomain.includes("chineserd")) {
  console.log("当前域名包含'chineserd'");
  host = "https://oms.chineserd.com/api/"; // 正式地址
} else {
  console.log("当前域名不包含'chineserd'");
  host = "https://oms.admin.zhixinlive.com/api/";
}

const burialPointPage = host + "student/studentBurialPointData/pageDataAdd"; //页面埋点数据  post
const burialPointEvent = host + "student/studentBurialPointData/eventDataAdd"; //事件埋点数据 post
export default class BuriedPoint {
  constructor(options) {
    this.options = options;
    this.browserInfo = {};
    this.userIp = {};
    // 首次进入事件
    this.firstTime = 0;

    this.devId = localStorage.getItem("school_devid");
    if (!this.devId) {
      let charts =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let strRandom = "";
      for (let i = 0; i < 32; i++) {
        strRandom += charts.charAt(Math.floor(Math.random() * charts.length));
      }

      this.devId = strRandom;
      localStorage.setItem("school_devid", strRandom);
    }
  }
  // 初始化记录页面埋点
  init() {
    this.commonParams();
  }
  // 公共参数，浏览器，用户定位信息
  async commonParams() {
    Promise.all([this.getBrowserInfo(), this.getUserIp()]).then((res) => {
      if (res[0]) {
        BuriedPoint.browserInfo = res[0];
      }
      if (res[1]) {
        BuriedPoint.userIp = res[1];
      }
      this.addPageData(1);
    });
  }
  // 获取浏览器信息
  getBrowserInfo() {
    let localBrowser =
      localStorage.getItem("localBrowser") &&
      JSON.parse(localStorage.getItem("localBrowser"));
    if (localBrowser) {
      return localBrowser;
    } else {
      const { name, version } = parser(navigator.userAgent).browser;
      let info = {
        // 浏览器
        browser: name,
        // 软件版本号
        appVer: version,
      };
      localStorage.setItem("localBrowser", JSON.stringify(info));
      return info;
    }
  }
  // 获取用户IP
  getUserIp() {
    return new Promise((resolve, reject) => {
      let userIpSession =
        sessionStorage.getItem("userIp") &&
        JSON.parse(sessionStorage.getItem("userIp"));
      if (userIpSession) {
        resolve(userIpSession);
      } else {
        axios
          .get("https://pro.ip-api.com/json?key=yXLkh9UcaD4HYW3")
          .then((res) => {
            if (res.data) {
              const { city, lat, lon, country, regionName, query } = res.data;
              const data = {
                // 城市
                city: city,
                // 经纬度坐标 格式：经度_纬度
                coordinate: lon + "_" + lat,
                // 国家
                country: country,
                // 省份
                province: regionName,
                ip: query,
              };
              sessionStorage.setItem("userIp", JSON.stringify(data));
              resolve(data);
            }
          })
          .catch((err) => {
            console.err("ip获取失败");
            resolve();
          });
      }
    });
  }
  // 添加页面埋点
  addPageData(status) {
    const params = {
      // 1=进入, 2=成功, 3=失败, 0=退出
      status: status,
      // 日志生成的时间
      logtime: Utils.zoneTimeConvert(new Date(), "YYYY-MM-DD HH:mm:ss"),
      // 来路页面
      fPage: sessionStorage.getItem("page"),
      // 来路页面ID
      fPageId:
        sessionStorage.getItem("pageId") &&
        Number(sessionStorage.getItem("pageId")),
      platform: 3,
      devId: this.devId,
      ...BuriedPoint.browserInfo,
      ...BuriedPoint.userIp,
      ...this.options,
    };

    if (status == 1) {
      BuriedPoint.firstTime = params.logtime;
    }

    if (status == 0) {
      // 页面停留时长
      let firstTime = new Date(BuriedPoint.firstTime).valueOf();
      let endTime = new Date(params.logtime).valueOf();
      params.stayTime = (endTime - firstTime) / 1000;
    }

    const { page, pageId } = this.options;
    if (page) {
      sessionStorage.setItem("page", page);
    }
    if (pageId) {
      sessionStorage.setItem("pageId", pageId);
    }
    // 退出时，添加页面状态
    axios
      .post(burialPointPage, [params])
      .then((res) => {
        this.firstTime = 0;
        if (res.data.code == 0) {
          console.log("页面埋点成功");
        } else {
          console.log("页面埋点失败");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // 添加事件埋点
  addEventData({ event, parm, objType }) {
    const params = [
      {
        // 事件ID事件埋点名称
        event: event,
        // 自定义参数
        parm: JSON.stringify(parm),
        // 事件对象类型
        objType: objType,
        // 日志生成的时间
        logtime: Utils.zoneTimeConvert(new Date(), "YYYY-MM-DD HH:mm:ss"),
        // 来路页面
        fPage: sessionStorage.getItem("page"),
        // 来路页面ID
        fPageId:
          sessionStorage.getItem("pageId") &&
          Number(sessionStorage.getItem("pageId")),
        platform: 3,
        devId: this.devId,
        ...BuriedPoint.browserInfo,
        ...BuriedPoint.userIp,
        ...this.options,
      },
    ];
    // axios({
    //   url: "/api/student/studentBurialPointData/eventDataAdd",
    //   method: "post",
    //   data: params,
    // })
    axios
      .post(burialPointEvent, params)
      .then((res) => {
        if (res.code == 0) {
          console.log("页面埋点成功");
        } else {
          console.log("页面埋点失败");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
