import BuriedPoint from '@/script/burialPoint'
export default { 
  data() {
    return {
      // 埋点使用
      buriedPoint: null,
      // 赞助商图片
      sponsorIcon:[
        require('@/assets/image/xiao/xiao2.png'),
        require('@/assets/image/xiao/xiao3.png'),
        require('@/assets/image/xiao/xiao1.png'),
        require('@/assets/image/xiao/xiao4.png')
      ],
      // 赞助商
      sponsorArr:[
        {
          leftValue:'贊助機構：',
          rightValue:'曾憲備慈善基金'
        },
        {
          leftValue:'主辦機構：',
          rightValue:'曾憲備慈善基金、新界校長會、中文路（香港）有限公司'
        },
        {
          leftValue:'合辦機構：',
          rightValue:'民建聯及郭玲麗立法會議員辦事處、活力慈善基金、梨木樹苗趣社'
        },
        {
          leftValue:'承辦機構：',
          rightValue:'中文路（香港）有限公司'
        },
        {
          leftValue:'支持機構：',
          rightValue:'九龍地域校長聯會、香港島校長聯會、光明英來學校、文冬漢語普通話中心、北京廣播電視台北京廣播影視培訓中心、朱偉林區議員辦事處、劉松剛區議員辦事處、李漢祥區議員辦事處、邱少雄區議員辦事處、陳孟宜區議員辦事處、林翠玲區議員辦事處、周劍豪區議員辦事處、王淑芬區議員辦事處、李細燕區議員辦事處、粵海控股集團有限公司'
        }
      ],
      // 
    };
  },
  computed: {},
  components: { 
  },
  watch: {},
  created() { 
    
  },
  beforeDestroy() {
    // this.buriedPoint.addPageData(0)
  },
  mounted() {},
  methods: {
    home_mdInitBut(){   
      this.buriedPoint = new BuriedPoint({
        page: 'web_zjb_recital2_competition',
        parm:JSON.stringify({'type':this.getQueryString('c')})
      })
      this.buriedPoint.init()
    },
    getQueryString: function(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    home_mdevInit(){ 
      // 点击埋点  无初始化情况
      this.buriedPoint = new BuriedPoint()
      this.buriedPoint.init()
      this.buriedPoint.addEventData({
        event: 'web_talk_recital2_click',
        parm:{'type':localStorage.getItem('c')}
      })
    },
    home_mdBut(){
      // 点击埋点
      this.buriedPoint.addEventData({
        event: 'web_talk_recital2_click',
        parm:JSON.stringify({'type':localStorage.getItem('c')})
      })
    }
  },
}; 