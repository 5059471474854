<template>
	<section class="header_view">
		<div class="header_top">
			<div class="header_left">
				<img :src="require('@/assets/image/icon/san.png')" alt="" class="icon_san" @click="popupShow = true" />
				<img :src="require('@/assets/image/icon/logo.png')" alt="" class="icon_logo" />
			</div>
			<div class="header_right" @click="aheftBut()"><img :src="require('@/assets/image/wa.png')" alt="">人工諮詢</div>
		</div>

		<van-popup v-model="popupShow" position="left" :style="{ height: '100%', width: '50%' }">
			<div class="box">
				<ul>
					<li v-for="(item, index) in nav" :key="index" @click="but(item)"
						:class="select == item.id ? 'select' : ''">
						{{ item.name }}
					</li>
				</ul>
			</div>
		</van-popup>
	</section>
</template> 

<script>
import home from '@/mixin/home_mixin';
	export default {
		name: "header_view",
		data() {
			return {
				nav: [
					{
						id: 6,
						name: "活動首頁",
						path: "/",
						href: null,
						type:1,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
					{
						id: 7,
						name: "團體賽誦材",
						path: "/material_team",
						href: null,
						type:1,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
					{
						id: 4,
						name: "團體賽章程",
						path: "/",
						href: "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-08/8c56a2847057a2823c91d064c4862eaa.pdf",
						type:3,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
					{
						id: 0,
						name: "個人賽回顧",
						path: "/individual",
						href: null,
						type:1,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
					// {
					// 	id: 2,
					// 	name: "個人賽誦材",
					// 	path: "/material",
					// 	href: null,
					// 	type:1,//1是本地跳轉   2,pdf預覽   3,外鏈
					// },
					// {
					// 	id:1,
					// 	name:'比賽指引',
					// 	path:'/',
					//   href:null,
					// },
					{
						id: 3,
						name: "個人賽成績公示",
						path: "/achievement",
						href: null,
						type:1,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
					// {
					// 	id: 4,
					// 	name: "比賽章程",
					// 	path: "/",
					// 	href: "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/e7bcc39b7ecd867074ab6a773fc3c0a0.pdf",
					// 	type:3,//1是本地跳轉   2,pdf預覽   3,外鏈
					// },
					{
						id: 5,
						name: "比賽APP下載",
						path: "/",
						href: "https://app.chineserd.com",
						type:3,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
					// {
					// 	id: 6,
					// 	name: "團體賽",
					// 	path: "/team",
					// 	href: null,
					// 	type:1,//1是本地跳轉   2,pdf預覽   3,外鏈
					// },
				],
				popupShow: false,
			};
		},
		props: {
			select: {
				type: String,
				default: '0',
			},
		},
		computed: {},
		components: {},
		mixins: [home],
		watch: {},
		created() {},
		mounted() {},
		methods: {
			aheftBut(e) {
				this.home_mdevInit();
				 window.open('https://wa.me/85291496563', "_blank");
			},
			heftBut(e) {
				this.$router.push(e);
			},
			but(e) {
				if (e.id == this.select) {
					console.log("不生效");
					this.popupShow = false;
				} else {
					console.log(e);
 
					switch (e.type) {
						case 1:
						this.$router.push(e.path);
							break;
						case 2:
							let baseurl = 'http://chineserd.cn/h5/html/regulations/index.html?url='+ e.href
							window.open(baseurl, "_blank");
							break;
						case 3:
							window.open(e.href, "_blank");
							break; 
						default:
							break;
					} 
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.header_view {
		width: 100%;
		height: 0.92rem;
		background-color: #ff891f;
		padding: 0rem 0.32rem;
		box-sizing: border-box;

		.header_top {
			position: fixed;
			top: 0;
			left: 0;

			width: 100%;
			height: 0.92rem;
			background-color: #ff891f;
			padding: 0rem 0.32rem;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-between;
			z-index: 999999;

			.header_left {
				display: flex;
				align-items: center;

				.icon_san {
					width: 0.48rem;
					height: 0.48rem;
					margin-right: 0.32rem;
				}

				.icon_logo {
					width: 0.56rem;
					height: 0.68rem;
				}
			}

			.header_right {
				background: #ffffff;
				border-radius: 0.4rem;
				padding: 0.15rem 0.44rem;
				box-sizing: border-box;
				font-size: 0.28rem;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #ff891f;
				vertical-align: middle; /* 将图片和文本垂直居中对齐 */ 

				img{
					width: 20px;
					height: 20px;
					margin-right: 4px;
					vertical-align: middle; /* 将图片和文本垂直居中对齐 */ 
				}
			}
		}

		.box {
			width: 100%;
			height: 100%;
			padding: 0.92rem 0 0 0;
			box-sizing: border-box;

			ul {
				width: 100%;
				height: 100%;

				li {
					width: 100%;
					height: 1.52rem;
					padding-left: 0.32rem;
					box-sizing: border-box;
					font-size: 0.4rem;
					font-family: PingFang SC-Semibold, PingFang SC;
					font-weight: 600;
					color: #333333;
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}

				.select {
					background: #f97226;
					color: #ffffff !important;
				}
			}
		}
	}
</style>
